// additional stuff considered good practice
html
{
  box-sizing: border-box;
}

*,
*:before,
*:after
{
  box-sizing: inherit;
}

audio,
canvas,
video {
  *display: inline;
  *zoom: 1;
}
body {
  font-size: 1rem;
  line-height: 1.4;
}
img {
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}
td {
  vertical-align: top;
}
table {
  border-spacing: 0px;
  border-collapse: collapse;
}
button,
input,
select,
textarea {
  *vertical-align: middle;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  *width: 13px;
  *height: 13px;
}
input:hover,
textarea:hover,
input:active,
textarea:active,
input:focus,
textarea:focus {
  outline: none;
}
label {
  cursor: pointer;
}
legend {
  white-space: normal;
  *margin-left: -7px;
}
textarea {
  resize: vertical;
}
nav ol,
nav ul {
  list-style: none;
  list-style-image: none;
}
blockquote,
q {
  quotes: none;
  &:before,
  &:after {
    content: '';
    content: none;
  }
}
dfn {
  cursor: help;
}
i,
em {
  font-style: italic;
}
code,
kbd,
pre,
samp {
  _font-family: 'courier new', monospace;
}
del {
  text-decoration: line-through;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
// better for grids:
img,
object,
embed { max-width: 100%; height: auto; }
object,
embed { height: 100%; }
iframe { width: 100%; }
// SVG icons:
svg[class^="icon"],
svg[class*=" icon"] {
  width: 1vw;
  height: 1vw;
  fill: currentColor;
}