// Eric Meyer's CSS Reset (v2)
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
blockquote,
b,
big,
body,
caption,
center,
cite,
code,
canvas,
del,
details,
dfn,
div,
em,
embed,
fieldset,
figure,
figcaption,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
label,
legend,
main,
mark,
menu,
nav,
object,
output,
p,
pre,
span,
kbd,
q,
ruby,
s,
samp,
section,
small,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
tfoot,
thead,
td,
th,
time,
tr,
tt,
u,
var,
video {
  border: 0px;
  font-size: 100%;
  font-family: sans-serif;
  font: inherit;
  margin: 0px;
  padding: 0px;
  vertical-align: baseline;
}
